<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="평가결과 환산표"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :usePaging="false"
        :columnSetting="false"
        :gridHeight="gridheight"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-plan-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      gridheight: '',
      grid: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '항목',
            align: 'left',
            sortable: false
          },
          {
            name: 'maxRealScoring',
            field: 'maxRealScoring',
            label: '최고실배점',
            align: 'center',
            style: 'width:200px',
            type: 'cost',
            sortable: false
          },
          {
            name: 'changeCalculation',
            field: 'changeCalculation',
            label: '환산계수',
            align: 'center',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'maxChangeScoring',
            field: 'maxChangeScoring',
            label: '최고환산점수',
            align: 'center',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'totalScore',
            field: 'totalScore',
            label: '실배점',
            align: 'center',
            color: 'blue',
            type: 'cost',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'score',
            field: 'score',
            label: '평가점수',
            align: 'center',
            color: 'blue',
            style: 'width:200px',
            sortable: false
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.gridheight = (window.innerHeight - 208) + 'px';
      this.listUrl = selectConfig.sop.pca.item.revs.result;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision, selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        let total_maxRealScoring = 0;
        let total_maxChangeScoring = 0;
        let total_totalScore = 0;
        let total_score = 0;
        this.$_.forEach(this.grid.data, item => {
          total_maxRealScoring += parseFloat(item.maxRealScoring ? item.maxRealScoring : '0');
          total_maxChangeScoring += parseFloat(item.maxChangeScoring ? item.maxChangeScoring : '0');
          total_totalScore += parseFloat(item.totalScore ? item.totalScore : '0');
          total_score += parseFloat(item.score ? item.score : '0');
        });
        this.grid.data.push({
          itemMstName: '합계',
          maxRealScoring: total_maxRealScoring,
          maxChangeScoring: total_maxChangeScoring,
          totalScore: total_totalScore,
          score: total_score.toFixed(2)
        });
      },);
    },
  }
};
</script>
